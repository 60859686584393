// @flow
import styled from 'styled-components';
import {
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  display,
  space,
  width,
  height,
  position,
  top,
  right,
  bottom,
  left,
  zIndex,
  opacity,
  borderRadius,
} from 'styled-system';


const Image = styled.img`
  ${space}
  ${width}
  ${height}
  ${top}
  ${right}
  ${bottom}
  ${left}
  ${position}
  ${display}
  ${zIndex}
  ${maxWidth}
  ${opacity}
  ${maxHeight}
  ${borderRadius}
  ${minHeight}
  ${minWidth}
  transition: ${props => props.transition || 'all 0s 0s ease'};
  transform: ${props => props.transform || 'none'};
  visibility: ${props => props.visibility || 'visible'};
  backface-visibility: ${props => props.backfaceVisibility || 'hidden'};

  &:hover {
    cursor: ${props => (props.hover ? 'pointer' : 'auto')};
  }
`;

export default Image;
