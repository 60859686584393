export const scrollToViewById = (
  id: string,
  config: ScrollIntoViewOptions = {
    behavior: 'smooth',
  },
) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView(config);
  }
};
