import React from 'react';

/**
 * v11 is when they make this official
 */
import Script from 'next/experimental-script';

export type GoogleTagManagerProps = {
  containerId: string;
};
export const GoogleTagManager = ({
  containerId,
}: GoogleTagManagerProps) => (
  <>
    <Script
      strategy="afterInteractive"
    >
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${containerId}');`}
    </Script>
    <noscript>
      <iframe
        title="Google Tag Manager iFrame"
        src={`https://www.googletagmanager.com/ns.html?id=${containerId}`}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  </>
);
