import React, {
  FunctionComponent,
} from 'react';

import { Box } from '@waglabs/box-next';
import { Container } from '@waglabs/container';
import { Divider } from '@waglabs/divider';
import { Flex } from '@waglabs/flex-next';
import {
  AppDownloadButton,
  Distribution,
} from '@waglabs/get-app-button';
import {
  SocialFacebookCircleIcon,
  SocialInstagramIcon,
  SocialTiktokIcon,
  SocialTwitterIcon,
  WagLogoIcon,
  SvgIconProps,
} from '@waglabs/icons';
import Link from '@waglabs/link';
import { Typography } from '@waglabs/typography';

import {
  ENV_URL_HOME,
  URL_FACEBOOK,
  URL_INSTAGRAM,
  URL_TIKTOK,
  URL_TWITTER,
} from '../../../../constants';

type Icon = {
  name: string;
  url: string;
  IconComponent: FunctionComponent<SvgIconProps>,
};

const icons: Icon[] = [
  {
    IconComponent: SocialFacebookCircleIcon,
    name: 'facebook',
    url: URL_FACEBOOK,
  },
  {
    IconComponent: SocialInstagramIcon,
    name: 'instagram',
    url: URL_INSTAGRAM,
  },
  {
    IconComponent: SocialTwitterIcon,
    name: 'twitter',
    url: URL_TWITTER,
  },
  {
    IconComponent: SocialTiktokIcon,
    name: 'tiktok',
    url: URL_TIKTOK,
  },
];

/**
 * Determines if the given index (i) is the last index in the given list of icons
 */
const isLastIcon = (_icons: Icon[], i: number) => i === _icons.length - 1;
const year = new Date().getFullYear();
export const Footer = () => (
  <>
    <Divider />

    <Container
      py={3}
    >
      <Box
        display={['block', null, 'flex']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <WagLogoIcon
            height="40px"
            width="80px"
          />
        </Box>

        <Box
          display="inline-block"
          width={['50%', null, 'auto']}
        >
          <Typography
            color="#999"
            variant="legal"
            fontFamily="muli"
          >
            {`® ${year} `}
            <Link
              href={ENV_URL_HOME}
              color="wagGreen"
            >
              Wag! Group Co.&nbsp;
            </Link>
            All rights reserved.
          </Typography>
        </Box>

        <Box
          display="inline-block"
          width={['50%', null, 'auto']}
          textAlign={['right', null, 'left']}
        >
          {icons.map(({
            url,
            IconComponent,
          }, i) => (
            <Link
              href={url}
              key={url}
            >
              <IconComponent
                height="32px"
                mr={isLastIcon(icons, i) ? 0 : ['4px', null, 4]}
                width="32px"
              />
            </Link>
          ))}
        </Box>

        <Flex
          justifyContent="center"
          mt={[4, 0]}
        >
          <AppDownloadButton
            distribution={Distribution.AppStore}
            mr={2}
            width="168px"
          />

          <AppDownloadButton
            distribution={Distribution.PlayStore}
            width="168px"
          />
        </Flex>
      </Box>
    </Container>
  </>
);
